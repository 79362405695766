import {
  useEffect, useLayoutEffect, useRef, useState, useCallback,
} from 'react'
import { isMobile } from 'react-device-detect'

function useFullHeight(percentage = 1, timeout = 200, minH) {
  const tID = useRef()

  const getHeight = useCallback(() => {
    if (typeof window === 'undefined') {
      return minH ? `${minH}px` : `${100 * percentage}vh`
    }
    const h = parseInt(global.innerHeight * percentage, 10)
    return h < minH ? `${minH}px` : `${h}px`
  }, [minH, percentage])

  const [height, setHeight] = useState(getHeight())

  const debouncedUpdateHeight = useCallback(() => {
    clearTimeout(tID.current)
    tID.current = setTimeout(() => setHeight(getHeight()), timeout)
  }, [getHeight, timeout])

  useLayoutEffect(() => {
    setHeight(getHeight())
  }, [getHeight])

  useEffect(() => {
    // Did Mount
    if (isMobile) {
      global.addEventListener('orientationchange', debouncedUpdateHeight)
    } else {
      global.addEventListener('resize', debouncedUpdateHeight)
    }

    // Will unmount
    return () => {
      clearTimeout(tID.current)
      if (isMobile) {
        global.removeEventListener('orientationchange', debouncedUpdateHeight)
      } else {
        global.removeEventListener('resize', debouncedUpdateHeight)
      }
    }
  }, [height, debouncedUpdateHeight])

  return () => height
}

export default useFullHeight
