import React, {
  useState, useRef, useEffect, useCallback,
} from 'react'
import PropTypes from 'prop-types'
import * as clipboard from 'clipboard-polyfill'
import ReactNotification from 'react-notifications-component'
import isSvg from 'is-svg'
import postcss from 'postcss'
import autoprefixer from 'autoprefixer'
import perfectionist from 'perfectionist'
// import throttle from 'lodash/throttle'

import { browserslist } from '../../../package.json'

// import 'react-notifications-component/dist/theme.css'
// import styled from 'styled-components'
// import uuid from 'react-uuid'

// Utils
import {
  getTransitionCss,
  getAnimationCss,
  getSvgCircleLength,
  getSvgEllipseLength,
  getCssBanner,
  getStrokeTransitionValue,
  getFillTransitionValue,
  getStrokeAnimationValue,
  getFillAnimationValue,
} from '../../utils'

// Hooks
import { useFullHeight, useOnClickOutside } from '../../hooks'

// Components
import {
  // Header,
  StrokePanel,
  MainPanel,
  FillPanel,
  CodeView,
  Toolbar,
  Footer,
  PasteSvgModal,
  Seo,
  CarbonCustom,
  CarbonAds,
  // ProductHunt,
  // Header,
  // ToggleDraw,
} from '../../components'

import {
  // BtnClose,
  BtnOptions,
  BtnLink,
  // BtnMenu,
} from '../../components/Buttons'

// Styled Components
import {
  AppWrapper,
  // StyledHeader,
  // LogoWrapper,
  // Logo,
  AppContent,
  SidebarWrapper,
  SidebarSeparator,
  StageWrapper,
  // ToolbarWrapper,
  // ToolbarButtons,
  // ButtonsWrapper,
  // BtnPlay,
  // BtnCode,
  // BtnMarkup,
  SvgWrapper,
  CodeWrapper,
  CodeContent,
  CheckboxWrapper,
  LabelCheck,
  CheckMark,
  // SidebarFieldset,
  // FieldsetHeader,
  // FieldsetTitle,
  // BtnCloseCode,
  GeneratedCode,
  GeneratedCodeAnim,
  // CodeHeader,
  // Ad,
  BtnWrapper,
  BtnCloseCodeWrapper,
  BtnCloseCode,
  BtnApply,
  // ToggleInput,
} from '../../styles/layout'

// Constants
import {
  INITIAL_PIC,
  EASE,
  // DARK_GRAY,
  BTN_ICON,
  ADS_CARBON_PLACEMENT,
  ADS_CARBON_ID,
  ADS_CARBON_CUSTOM_ID,
  LIGHT,
} from '../../constants'

const getEasings = () => Object.keys(EASE).reduce(
  (acc, name) => [
    ...acc,
    {
      name,
      value: EASE[name],
    },
  ],
  [],
)

// console.log(browserslist.production)

// Values for the pickers
const easings = getEasings()
const direction = [{ name: 'normal', value: 'normal' }, { name: 'reverse', value: 'reverse' }]

const Home = ({ updateIsHeaderShown }) => {
  // This is where we will be saving the SVG code
  const [svgCode, setSvgCode] = useState('')
  const [codeShown, setCodeShown] = useState(false)
  const [svgWrapperCode, setSvgWrapperCode] = useState(INITIAL_PIC)
  const [svgWrapperShown, setSvgWrapperShown] = useState(false)
  // const svgCodeRef = useRef(svgCode)
  // svgCodeRef.current = svgCode

  const [transitionCode, setTransitionCode] = useState('')
  const [postTransitionCode, setPostTransitionCode] = useState('')
  const [animationCode, setAnimationCode] = useState('')
  const [postAnimationCode, setPostAnimationCode] = useState('')

  // UI elements state
  const [disabled, setDisabled] = useState(false)

  // Global settings
  const [bgColor, setBgColor] = useState(LIGHT)
  const [svgElemClass, setSvgElemClass] = useState('svg-elem')
  const [animationType, setAnimationType] = useState('transition')

  const [svgElems, setSvgElems] = useState([])
  const svgElemsRef = useRef(svgElems)

  // Sidebar
  const [sidebarShown, setSidebarShown] = useState(false)

  // Stroke settings
  const [shouldAnimateStroke, setShouldAnimateStroke] = useState(true)
  const shouldAnimateStrokeRef = useRef(shouldAnimateStroke)
  const [hasStrokes, setHasStrokes] = useState(true)
  const hasStrokesRef = useRef(hasStrokes)
  // Stroke animation settings
  const [strokeDuration, setStrokeDuration] = useState(1)
  const [strokeStaggerStep, setStrokeStaggerStep] = useState(0.12)
  const [strokeDelay, setStrokeDelay] = useState(0)
  const [strokeDirection, setStrokeDirection] = useState('normal')
  const [strokeEasing, setStrokeEasing] = useState(EASE.easeInSine)
  // const [stroke, setStroke] = useState('#333')
  // const [strokeWidth, setStrokeWidth] = useState('1px')
  // const [strokeDasharray, setStrokeDasharray] = useState()
  // const [strokeDashoffset, setStrokeDashoffset] = useState(0)

  // Fill settings
  const [shouldAnimateFill, setShouldAnimateFill] = useState(true)
  const shouldAnimateFillRef = useRef(shouldAnimateStroke)
  const [hasFills, setHasFills] = useState(true)
  const hasFillsRef = useRef(hasFills)
  // const [fill, setFill] = useState('#999')
  // Fill animation settings
  const [fillDuration, setFillDuration] = useState(0.7)
  const [fillStaggerStep, setFillStaggerStep] = useState(0.1)
  const [fillDelay, setFillDelay] = useState(0.8)
  const [fillEasing, setFillEasing] = useState(EASE.easeInSine)

  // Toolbar
  const [isDrawing, setIsDrawing] = useState(true) // other option is 'erase'

  // Paste SVG modal
  const [pasteSvgModalShown, setPasteSvgModalShown] = useState(false)

  // References
  const notificationEl = useRef(null)
  const svgWrapperEl = useRef(null)
  const fileReader = useRef(null)
  const animTID = useRef(null)
  const anim2TID = useRef(null)
  const anim3TID = useRef(null)
  const sidebarRef = useRef(null)
  useOnClickOutside(sidebarRef, () => setSidebarShown(false))

  // const carbonAd = useRef(null)

  // let animStrokeValue = ''
  // let animFillValue = ''

  // const { updateIsHeaderShown } = useContext(LayoutContext)

  const getFullHeight = useFullHeight()
  // const getFullHeight = useFullHeight(0.5, 100, 500)

  // --------------------------------------------------------------------------------------------
  // Post CSS (prefixes + minification)
  const [shouldAddPrefixes, setShouldAddPrefixes] = useState(true)
  const [shouldMinifiy, setShouldMinifiy] = useState(false)
  // Autoprefixer and formatter
  const autoprefixerOpts = {
    // overrideBrowserslist: ['>0.2%', 'not dead', 'not ie < 10', 'not op_mini all'],
    overrideBrowserslist: browserslist.production,
  }
  // https://github.com/ben-eb/perfectionist
  const perfectionistOpts = {
    indentSize: 2,
    trimLeadingZero: false,
    // indentChar: '\t', // 'space' is default
    format: shouldMinifiy ? 'compressed' : 'expanded', // compressed, compact, expanded
  }

  const postCssPlugins = [...(shouldAddPrefixes ? [autoprefixer(autoprefixerOpts)] : []), perfectionist(perfectionistOpts)]

  // const cssProcessor = postcss([autoprefixer(autoprefixerOpts), perfectionist(perfectionistOpts)])

  const processCode = useCallback(async () => {
    const banner = getCssBanner()
    const transCode = await postcss(postCssPlugins).process(transitionCode, { from: undefined })
    // eslint-disable-next-line no-console
    transCode.warnings().forEach(w => console.warn(w.toString()))
    setPostTransitionCode(`${banner}\n${transCode.css}`)

    const animCode = await postcss(postCssPlugins).process(animationCode, { from: undefined })
    // eslint-disable-next-line no-console
    animCode.warnings().forEach(w => console.warn(w.toString()))
    setPostAnimationCode(`${banner}\n${animCode.css}`)
  }, [postCssPlugins, animationCode, transitionCode])
  // --------------------------------------------------------------------------------------------

  const notificationOpts = {
    title: '',
    // message: 'Copied SVG code to clipboard. Now go paste it somewhere :)',
    type: 'success',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animated', 'slide-in-top'],
    animationOut: ['animated', 'slide-out-top'],
    dismiss: { duration: 2000 },
    dismissable: { click: true },
  }

  const checkForFillsAndStrokes = () => {
    const fills = []
    const strokes = []

    svgElemsRef.current.forEach(el => {
      // Use the computed styles, it's safer
      const compStyle = getComputedStyle(el)
      const compFill = compStyle.getPropertyValue('fill')
      const compStroke = compStyle.getPropertyValue('stroke')
      const compStrokeWidth = compStyle.getPropertyValue('stroke-width')

      // Check if the SVG element has 'fill' or 'stroke' properties.
      // If it has 'fill' make sure it's not set to 'none' and save
      // it as element's original value. If it has 'stroke' make sure
      // that it is not set to one and that its width is greater than 0
      /* eslint-disable no-param-reassign */
      el.originalStyleFill = el.originalStyleFill || el.style.fill
      // console.log(el.originalStyleFill)
      el.shouldCleanUpFillStyle = el.shouldCleanUpFillStyle || el.originalStyleFill === '' || el.originalStyleFill === undefined
      // console.log(el.shouldCleanUpFillStyle)
      el.originalFillValue = el.originalFillValue || (compFill !== 'none' && compFill)
      const stroke = compStroke && compStroke !== 'none' && parseFloat(compStrokeWidth) > 0

      if (el.originalFillValue) {
        fills.push(el.originalFillValue)
      }
      if (stroke) {
        strokes.push(stroke)
      }
      /* eslint-disable no-param-reassign */
    })

    hasFillsRef.current = fills.length > 0
    setHasFills(hasFillsRef.current)
    if (!hasFillsRef.current) {
      setShouldAnimateFill(hasFillsRef.current)
    }

    hasStrokesRef.current = strokes.length > 0
    setHasStrokes(hasStrokesRef.current)
    if (!hasStrokesRef.current) {
      setShouldAnimateStroke(hasStrokesRef.current)
    }

    if (fills.length < 1 && strokes.length < 1) {
      notificationEl.current.addNotification({
        ...notificationOpts,
        dismiss: { duration: 5000 },
        type: 'danger',
        message: 'Could not find any strokes or fills. Please read the how to use page to learn how to prepare your SVG.',
      })
    }
  }

  // const animate = (play = true) => {
  const animate = useCallback(() => {
    let tCode = ''
    let aCode = ''
    let transValue = ''
    let animValue = ''
    let strokeDashoffsetEndValue = 0

    svgElemsRef.current.forEach((el, i) => {
      /* eslint-disable no-param-reassign */
      const gap = 2

      // eslint-disable-next-line no-nested-ternary
      const maxLength = el instanceof SVGCircleElement
        ? getSvgCircleLength(el) + gap
        : el instanceof SVGEllipseElement
          ? getSvgEllipseLength(el) + gap
          : el.getTotalLength() + gap

      // console.log(el instanceof SVGCircleElement)
      // console.log(el instanceof SVGEllipseElement)
      // console.log(el.tagName)
      // console.log(el.getBBox())
      // console.log(maxLength)
      // console.log('-------------------')

      // Get the element's original fill value and use it
      // as the end value for the fill transition
      // el.originalFillValue = el.originalFillValue || (el.getAttribute('fill') || el.style.fill)
      const fillEndValue = isDrawing ? el.originalFillValue : 'transparent'
      const fillStartValue = isDrawing ? 'transparent' : el.originalFillValue

      // Reset fill and stroke
      el.style.fill = el.originalStyleFill
      el.style.strokeDasharray = ''
      el.style.strokeDashoffset = ''

      // Apply starting fill values
      if (shouldAnimateFillRef.current && el.originalFillValue) {
        el.style.fill = fillStartValue
      }

      // Apply starting stroke values
      let strokeDasharrayStartValue
      let strokeDashoffsetStartValue

      if (shouldAnimateStrokeRef.current) {
        strokeDasharrayStartValue = maxLength
        strokeDashoffsetStartValue = isDrawing ? maxLength : 2 * maxLength

        el.style.strokeDasharray = `${strokeDasharrayStartValue}px` // Explicit use of pixels for IE/Edge
        el.style.strokeDashoffset = `${strokeDashoffsetStartValue}px` // Explicit use of pixels for IE/Edge
      }

      // Reset transition
      el.style.transition = 'none'

      // Add necessary classes
      el.classList.add(`${svgElemClass}-${i + 1}`)

      // // Trigger layout (offsetWidth doesn't work for SVG elements)
      // el.getBoundingClientRect()

      const strokeOpts = {
        strokeDuration,
        strokeDelay,
        strokeStaggerStep,
        strokeEasing,
        i,
      }

      const fillOpts = {
        fillDuration,
        fillDelay,
        fillStaggerStep,
        fillEasing,
        i,
      }

      // Apply the end values. We are getting the state
      // from the reference because timeout function is a closure.
      anim2TID.current = setTimeout(() => {
        // ---------------------------------------------------------------------------
        // Get CSS transition values for stroke and fill
        const transStrokeVal = hasStrokesRef.current && shouldAnimateStrokeRef.current ? getStrokeTransitionValue(strokeOpts) : ''
        const transFillVal = hasFillsRef.current && shouldAnimateFillRef.current ? getFillTransitionValue(fillOpts) : ''

        // Filter stroke and fill transition from empty values and convert the array to comma separated string
        transValue = [transStrokeVal, transFillVal].filter(t => t).join(', ')
        // ---------------------------------------------------------------------------

        // ---------------------------------------------------------------------------
        // Get CSS animation values for stroke and fill
        const animStrokeVal = hasStrokesRef.current && shouldAnimateStrokeRef.current ? getStrokeAnimationValue(strokeOpts) : ''
        const animFillVal = hasFillsRef.current && shouldAnimateFillRef.current ? getFillAnimationValue(fillOpts) : ''

        // Filter stroke and fill animation from empty values and convert the array to comma separated string
        animValue = [animStrokeVal, animFillVal].filter(a => a).join(', ')
        // ---------------------------------------------------------------------------

        if (shouldAnimateStrokeRef.current && strokeDashoffsetEndValue !== undefined) {
          // Calculate the ending stroke dashoffset lengths based on animation direction and type (draw/erase)
          const strokeDashoffsetEndDrawValue = strokeDirection === 'normal' ? 0 : 2 * maxLength
          const strokeDashoffsetEndEraseValue = strokeDirection === 'normal' ? maxLength : 3 * maxLength
          // Set the ending stroke dashoffset value based on whether we are drawing or erasing
          strokeDashoffsetEndValue = isDrawing ? strokeDashoffsetEndDrawValue : strokeDashoffsetEndEraseValue
          el.style.strokeDashoffset = `${strokeDashoffsetEndValue}px` // Explicit use of pixels for IE/Edge
        }

        // Animate fill if it is toggled
        // and if there is an ending fill value
        if (shouldAnimateFillRef.current && fillEndValue !== undefined) {
          el.style.fill = fillEndValue
        }

        el.style.transition = transValue

        const animOpts = {
          svgElemClass,
          strokeDasharrayStartValue,
          strokeDashoffsetStartValue,
          shouldAnimateFill,
          shouldAnimateStroke,
          strokeDashoffsetEndValue,
          i,
        }

        // Generate the CSS transition code
        const currCss = getTransitionCss({
          ...animOpts,
          fillEndValue,
          transValue,
        })

        tCode += currCss

        // Generate the CSS animation code
        const currAnim = getAnimationCss({
          ...animOpts,
          fillEndValue,
          animValue,
        })

        aCode += currAnim
      }, 100)
      /* eslint-enable no-param-reassign */
    })

    // Save the generated CSS code to state
    clearTimeout(anim3TID.current)
    anim3TID.current = setTimeout(() => {
      setTransitionCode(tCode)
      setAnimationCode(aCode)
    }, 100)
  }, [
    isDrawing,
    fillDelay,
    fillDuration,
    fillEasing,
    fillStaggerStep,
    shouldAnimateFill,
    shouldAnimateStroke,
    strokeDelay,
    strokeDirection,
    strokeDuration,
    strokeEasing,
    strokeStaggerStep,
    svgElemClass,
  ])

  // Remove the inline styles that weren't originally there
  const cleanUpSvgCode = () => {
    svgElemsRef.current.forEach(el => {
      /* eslint-disable no-param-reassign */
      el.style.transition = ''
      el.style.strokeDasharray = ''
      el.style.strokeDashoffset = ''
      el.style.fill = shouldAnimateFill && el.shouldCleanUpFillStyle ? '' : el.originalStyleFill
      if (el.getAttribute('style') === '') {
        el.removeAttribute('style')
        /* eslint-disable no-param-reassign */
      }
    })
  }

  const updateSvgCode = () => {
    cleanUpSvgCode()
    const svg = svgWrapperEl.current.innerHTML
    setSvgCode(svg)
    // Show code
    setCodeShown(prevCodeShown => !prevCodeShown)
    updateIsHeaderShown(false)
  }

  const updateSvgElemClass = val => {
    svgElemsRef.current.forEach((el, i) => {
      el.classList.remove(`${svgElemClass}-${i + 1}`)
      el.classList.add(`${val}-${i + 1}`)
    })
    // console.log(`setting class name to: ${val} (from ${svgElemClass})`)
    const svgGraphic = svgWrapperEl.current.querySelector('svg')
    const {
      // x, y,
      width,
      height,
    } = svgGraphic.viewBox.baseVal
    const w = svgGraphic.getAttribute('width') || svgGraphic.style.width
    const h = svgGraphic.getAttribute('height') || svgGraphic.style.height

    // console.log('viewBox values')
    // console.log('------------------------------------------')
    // console.log(`x: ${x}, y: ${y}, width: ${width}, height: ${height}`)
    // console.log(`x: ${x}, y: ${y}, width: ${width}, height: ${height}`)

    // console.log('SVG values')
    // console.log('------------------------------------------')
    // console.log(`width: ${w}`)
    // console.log(`height: ${h}`)
    const parentW = svgWrapperEl.current.offsetWidth
    const parentH = svgWrapperEl.current.offsetHeight
    // console.log(`width: ${parentWidth}`)

    // svgGraphic.style.width = (!w || w <= 0) ? `${width}px` : '100%'
    // svgGraphic.style.height = (!h || h <= 0) ? `${height}px` : '100%'
    svgGraphic.setAttribute('width', !w || w <= 0 || w <= parentW ? width : '100%')
    svgGraphic.setAttribute('height', !h || h <= 0 || h <= parentH ? height : '100%')

    setSvgElemClass(val)
    const svg = svgWrapperEl.current.innerHTML
    // console.log(svg)

    setSvgCode(svg)
    // setSvgElems(Array.from(svgWrapperEl.current.querySelectorAll('path, circle, polygon, rect, ellipse, polyline, line')))
    // console.log(svgCode)
  }

  const initSvgElems = () => {
    shouldAnimateStrokeRef.current = true
    shouldAnimateFillRef.current = true
    setShouldAnimateStroke(shouldAnimateStrokeRef.current)
    setShouldAnimateFill(shouldAnimateFillRef.current)
    svgElemsRef.current = Array.from(svgWrapperEl.current.querySelectorAll('path, circle, polygon, rect, ellipse, polyline, line'))
    setSvgElems(svgElemsRef.current)
    updateSvgElemClass(svgElemClass)
    checkForFillsAndStrokes()
  }

  const fileLoaded = () => {
    setSvgWrapperShown(false)
    setSvgWrapperCode(fileReader.current.result)
    setDisabled(false)
    initSvgElems()
    // animate()
    clearTimeout(animTID.current)
    animTID.current = setTimeout(() => {
      setSvgWrapperShown(true)
      animate()
    }, 100)
  }

  const readFile = e => {
    const file = e.target.files[0]

    if (file instanceof Blob) {
      fileReader.current = new FileReader()
      fileReader.current.onloadend = fileLoaded
      fileReader.current.readAsText(file)
    } else {
      // eslint-disable-next-line no-console
      console.log('File not provided...')
    }
  }

  // const updateStroke = color => {
  //   svgElems.forEach(el => {
  //     // eslint-disable-next-line no-param-reassign
  //     el.style.stroke = color
  //   })
  // }

  // const updateFill = color => {
  //   svgElems.forEach(el => {
  //     // eslint-disable-next-line no-param-reassign
  //     el.style.fill = color
  //   })
  // }

  // const updateStrokeWidth = width => {
  //   svgElems.forEach(el => {
  //     // eslint-disable-next-line no-param-reassign
  //     el.style.strokeWidth = width
  //   })
  // }

  const mainPanelProps = {
    disabled,
    svgElemClass,
    bgColor,
    animationType,
    onChangeSvgFile: readFile,
    onClickPasteSvg: () => {
      setPasteSvgModalShown(true)
      updateIsHeaderShown(false)
    },
    onChangeSvgElementClass: val => updateSvgElemClass(val),
    // onChangeBgColor: val => setBgColor(val),
    onChangeBgColor: col => {
      const newCol = `hsla(${col.h}, ${col.s * 100}%, ${col.l * 100}%, ${col.a})`
      setBgColor(newCol)
    },
    onChangeAnimationType: val => setAnimationType(val),
  }

  const strokePanelProps = {
    easings,
    direction,
    disabled,
    hasStrokes,
    shouldAnimateStroke,
    // stroke,
    // strokeWidth,
    strokeDuration,
    strokeStaggerStep,
    strokeDelay,
    strokeEasing,
    strokeDirection,
    onChangeAnimateStroke: val => {
      shouldAnimateStrokeRef.current = val
      setShouldAnimateStroke(shouldAnimateStrokeRef.current)
      // setShouldAnimateStroke(val)
    },
    // onChangeStroke: val => {
    //   setStroke(val)
    //   updateStroke(val)
    // },
    // onChangeStrokeWidth: val => {
    //   setStrokeWidth(val)
    //   updateStrokeWidth(val)
    // },
    onChangeStrokeDuration: val => setStrokeDuration(val || 0),
    onChangeStrokeStaggerStep: val => setStrokeStaggerStep(val || 0),
    onChangeStrokeDelay: val => setStrokeDelay(val || 0),
    onChangeStrokeEasing: val => setStrokeEasing(val),
    onChangeStrokeDirection: val => setStrokeDirection(val),
  }

  const fillPanelProps = {
    easings,
    disabled,
    shouldAnimateFill,
    hasFills,
    // fill,
    fillDuration,
    fillStaggerStep,
    fillDelay,
    fillEasing,
    onChangeAnimateFill: val => {
      shouldAnimateFillRef.current = val
      setShouldAnimateFill(shouldAnimateFillRef.current)
      // setShouldAnimateFill(val)
    },
    // onChangeFill: val => {
    //   setFill(val)
    //   updateFill(val)
    // },
    onChangeFillDuration: val => setFillDuration(val || 0),
    onChangeFillStaggerStep: val => setFillStaggerStep(val || 0),
    onChangeFillDelay: val => setFillDelay(val || 0),
    onChangeFillEasing: val => setFillEasing(val),
  }

  const closeCodeView = event => {
    if (event) {
      event.preventDefault()
    }
    setCodeShown(false)
    setSidebarShown(false)
    setPasteSvgModalShown(false)
    updateIsHeaderShown(true)
  }

  const codeShownProps = {
    codeShown,
  }

  const toggleSidebar = e => {
    e.preventDefault()
    setSidebarShown(currShown => !currShown)
  }

  const toolbarProps = {
    disabled: ((!hasFills || !shouldAnimateFill) && (!hasStrokes || !shouldAnimateStroke)) || disabled,
    // ToggleDraw
    onChangeDraw: val => setIsDrawing(val),
    toggleDrawChecked: isDrawing,
    // BtnPlay
    onClickBtnPlay: animate,
    // BtnCode
    onClickBtnCode: updateSvgCode,
  }

  const copySvgToClipboard = e => {
    e.preventDefault()
    clipboard.writeText(svgCode).then(() => {
      // console.log('Copied SVG code to clipboard. Go paste it somewhere :)')
      notificationEl.current.addNotification({
        ...notificationOpts,
        message: 'SVG code is copied to clipboard. Now go paste it somewhere :)',
      })
    })
  }

  const copyCssToClipboard = e => {
    e.preventDefault()
    const cssCode = animationType === 'transition' ? postTransitionCode : postAnimationCode
    clipboard.writeText(cssCode).then(() => {
      notificationEl.current.addNotification({
        ...notificationOpts,
        message: 'CSS code is copied to clipboard. Go paste it somewhere :)',
      })
    })
  }

  const onApplyPasteSvg = val => {
    if (isSvg(val)) {
      setSvgWrapperShown(false)
      setPasteSvgModalShown(false)
      setSvgWrapperCode(val)
      clearTimeout(animTID.current)
      animTID.current = setTimeout(() => {
        initSvgElems()
        setSvgWrapperShown(true)
        // animate() // It is implied from useEffect()
      }, 10)
    } else {
      notificationEl.current.addNotification({
        ...notificationOpts,
        type: 'danger',
        message: 'Invalid SVG. Check your code again.',
      })
    }
  }

  // eslint-disable-next-line consistent-return
  // const refreshAds = () => {
  //   // console.log('refreshing...')
  //   // console.log(carbonAd.current)
  //   // eslint-disable-next-line no-underscore-dangle
  //   if (window._carbonads) {
  //     // eslint-disable-next-line no-underscore-dangle
  //     window._carbonads.refresh(document.getElementById('carbon_refresh')) // Should use useRef for this
  //   }
  // }

  // const handleAppClick = useCallback(
  //   throttle(refreshAds, ADS_REFRESH_INTERVAL, {
  //     trailing: true,
  //     leading: false,
  //   }),
  //   [],
  // )

  // Mounted
  useEffect(
    () => {
      initSvgElems()
      setSvgWrapperShown(false)
      // animate()

      return () => {
        clearTimeout(animTID.current)
        clearTimeout(anim2TID.current)
        clearTimeout(anim3TID.current)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  ) // Add empty array as dependency to avoid endless re-rendering

  // Process CSS code every time transitionCode or animationCode changes.
  // Also, process it when toggling autoprefixing and minifying on or off
  useEffect(() => {
    processCode()
  }, [processCode, transitionCode, animationCode, shouldAddPrefixes, shouldMinifiy])

  // useEffect(() => {
  //   if (!hasFills && !hasStrokes) {
  //     notificationEl.current.addNotification({
  //       ...notificationOpts,
  //       dismiss: { duration: 5000 },
  //       type: 'danger',
  //       message: 'Could not find any strokes or fills. Please read the how to use page to learn how to prepare your SVG.',
  //     })
  //   }
  // }, [hasFills, hasStrokes, notificationOpts])

  useEffect(() => {
    clearTimeout(animTID.current)
    animTID.current = setTimeout(() => {
      setSvgWrapperShown(true)
      animate()
    }, 700)

    return () => {
      clearTimeout(animTID.current)
    }
  }, [animate, strokeDelay, strokeDuration, strokeEasing, strokeDirection, strokeStaggerStep, fillDuration, fillDelay, fillEasing, fillStaggerStep])

  // const appWrapperRef = useRef(null)
  const preventScroll = e => {
    const cancelable = e.type === 'touchmove' ? e.cancelable : true
    if (cancelable) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    const wrapper = svgWrapperEl.current
    // appWrapperRef.current.addEventListener('touchmove', preventScroll)
    wrapper.addEventListener('touchmove', preventScroll, false)

    return () => {
      // appWrapperRef.current.removeEventListener('touchmove', preventScroll)
      wrapper.removeEventListener('touchmove', preventScroll, false)
    }
  }, [])

  return (
    <>
      {/* <Seo title="Animate SVG paths and fills with CSS" /> */}
      <Seo title="SVG Drawing Animation Generator" />
      {/* <AppWrapper onClick={handleAppClick} height={getFullHeight()}> */}
      <AppWrapper height={getFullHeight()}>
        {/* Toolbar */}
        <Toolbar {...toolbarProps} codeShown={codeShown || pasteSvgModalShown || sidebarShown} />

        {/* Button Close */}
        <BtnCloseCodeWrapper>
          <BtnCloseCode codeShown={codeShown || pasteSvgModalShown || sidebarShown}>
            <BtnLink type="button" kind="toolbar" text="Close" icon={BTN_ICON.CLOSE} onClick={closeCodeView} />
          </BtnCloseCode>
        </BtnCloseCodeWrapper>

        {/* App */}
        <AppContent>
          <BtnOptions onClick={toggleSidebar} codeShown={sidebarShown} />

          {/* Side panels */}
          <SidebarWrapper shown={sidebarShown} ref={sidebarRef}>
            <MainPanel {...mainPanelProps} />
            <SidebarSeparator />
            <StrokePanel {...strokePanelProps} />
            <SidebarSeparator />
            <FillPanel {...fillPanelProps} />
            <BtnApply kind="big" title="Aply" onClick={closeCodeView}>
              Apply
            </BtnApply>
            <SidebarSeparator />
            <CarbonAds serve={ADS_CARBON_ID} placement={ADS_CARBON_PLACEMENT} />
          </SidebarWrapper>

          {/* Stage  */}
          <StageWrapper {...codeShownProps}>
            {/* <Toolbar {...toolbarProps} /> */}
            <SvgWrapper shown={svgWrapperShown} bgColor={bgColor} dangerouslySetInnerHTML={{ __html: svgWrapperCode }} ref={svgWrapperEl} />
            {/* <Ad ref={carbonAd} /> */}
            <CarbonCustom serve={ADS_CARBON_CUSTOM_ID} placement={ADS_CARBON_PLACEMENT} />
            {/* <CarbonCustom serve="CKYD623M" placement="demo" /> */}
            {/* <CarbonAds serve={ADS_CARBON_ID} placement={ADS_CARBON_PLACEMENT} /> */}
            {/* <ProductHunt /> */}
          </StageWrapper>

          {/* Code */}
          <CodeWrapper>
            <CodeContent codeShown={pasteSvgModalShown}>
              <PasteSvgModal onApplyPasteSvg={onApplyPasteSvg} disabled={!pasteSvgModalShown} />
            </CodeContent>

            <CodeContent {...codeShownProps}>
              {/* List of SVG elements, their IDs and classes */}
              {/* {svgElems && svgElems.length > 0 && (
                <ul>
                  {svgElems.map(el => (
                    <li key={uuid()}>{`type: ${el.nodeName}, id: ${el.id}, class: ${el.classList}`}</li>
                  ))}
                </ul>
              )} */}

              {/* Generated code */}
              <GeneratedCode>
                {svgCode && <CodeView title="SVG Code" code={svgCode} syntax="svg" />}

                <BtnWrapper>
                  <BtnLink kind="big" text="Copy SVG" icon={BTN_ICON.COPY} onClick={copySvgToClipboard} />
                </BtnWrapper>
              </GeneratedCode>

              <GeneratedCodeAnim>
                {animationType === 'transition' && postTransitionCode && <CodeView title="Transition Code" code={postTransitionCode} syntax="css" />}
                {animationType === 'animation' && postAnimationCode && <CodeView title="Animation Code" code={postAnimationCode} syntax="css" />}

                <CheckboxWrapper>
                  <LabelCheck>
                    <input type="checkbox" checked={shouldAddPrefixes} onChange={e => setShouldAddPrefixes(e.target.checked)} />
                    <CheckMark />
                    <span>Autoprefixer</span>
                  </LabelCheck>
                  <LabelCheck>
                    <input type="checkbox" checked={shouldMinifiy} onChange={e => setShouldMinifiy(e.target.checked)} />
                    <CheckMark />
                    <span>Minify</span>
                  </LabelCheck>
                </CheckboxWrapper>
                <BtnWrapper>
                  <BtnLink
                    kind="big"
                    text={`Copy ${animationType === 'animation' ? 'Animation' : 'Transition'} code`}
                    icon={BTN_ICON.COPY}
                    onClick={copyCssToClipboard}
                  />
                </BtnWrapper>
              </GeneratedCodeAnim>
            </CodeContent>
          </CodeWrapper>
        </AppContent>

        {/* Footer */}
        <Footer />
        <ReactNotification ref={notificationEl} />
      </AppWrapper>
    </>
  )
}

Home.propTypes = {
  updateIsHeaderShown: PropTypes.func,
}

export default Home
